import Plugin from '@ckeditor/ckeditor5-core/src/plugin';

import customFieldIcon from './customField.svg';
import {Model, ButtonView, createDropdown, addListToDropdown} from "@ckeditor/ckeditor5-ui";
import {Collection} from "@ckeditor/ckeditor5-utils";

const CUSTOM_FIELD = 'customField';

export default class CustomField extends Plugin {
  static get pluginName() {
    return 'Custom Field';
  }

  init() {
    const editor = this.editor;
    const componentFactory = editor.ui.componentFactory;
    const {fields} = {...{
      fields: [],
    },...(editor.config.get( 'customField' ) || {})};

    componentFactory.add( CUSTOM_FIELD, locale => {
      const dropdownView = createDropdown( locale );

      addListToDropdown(dropdownView, _prepareListOptions(fields, CUSTOM_FIELD));

      // Configure dropdown properties an behavior.
      dropdownView.buttonView.set({
        label: 'Custom Field',
        icon: customFieldIcon,
        tooltip: true,
        isVisible: fields.length > 0
      });

      this.listenTo(dropdownView, 'execute', evt => {
        editor.model.change(writer => {
          const insertPosition = editor.model.document.selection.getFirstPosition();
          const key = evt.source.commandParam;
          writer.insertText(key, insertPosition);
        });
        editor.editing.view.focus();
      })

      // this.listenTo( view, 'execute', () => {
      //   // if (popup || (popup && !popup.closed)) {
      //   //   popup.focus();
      //   // } else {
      //   //   popup = window.open(
      //   //     "",
      //   //     "_blank",
      //   //     `popup,width=${width},height=${height}`,
      //   //   );
      //   //
      //   //   if (popup) {
      //   //     // sometimes it set to null and causing issues after
      //   //     popup.document.title = "Custom Field";
      //   //     popup.document.write(beforeContent + editor.getData() + afterContent);
      //   //     popup.addEventListener("beforeunload", () => {
      //   //       popup = null;
      //   //     });
      //   //   }
      //   // }
      // } );

      return dropdownView;
    } );
  }
}

function _prepareListOptions( options, command ) {
  const itemDefinitions = new Collection();

  for ( const option of options ) {
    const def = {
      type: 'button',
      model: new Model( {
        commandName: command,
        commandParam: option.key,
        label: option.label,
        //class: '',
        withText: true
      } )
    };

    // Add the option to the collection.
    itemDefinitions.add( def );
  }

  return itemDefinitions;
}
